<template>
  <div class="file-create-desktop">
    <v-card
        :disabled="file.loading"
        :loading="file.loading"
        class="file-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-plus-box"
            title="ثبت فایل از دیوار"/>

        <template>
          <v-btn :href="divar.source" target="_blank" color="error">
            مشاهده فایل در دیوار
          </v-btn>
        </template>
      </v-card-title>

      <v-card-text>

        <v-row class="my-4" dense>

          <v-col :cols="3">
            <v-text-field
                dense
                @blur="fetchFilesWithMobile($event.target.value)"
                v-model="file.phone1"
                outlined
                label="شماره موبایل مالک"
                :messages="divar.phoneNumber"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.phone2"
                hide-details
                outlined
                @blur="fetchFilesWithMobile($event.target.value)"
                label="شماره موبایل مالک"

            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.phone3"
                hide-details
                outlined
                @blur="fetchFilesWithMobile($event.target.value)"
                label="شماره موبایل مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                v-model="file.dateSelection"
                hide-details
                dense
                outlined
                label="تاریخ"
                class="start-date-picker"
            />
            <date-picker
                auto-submit
                v-model="file.dateSelection"
                format="jYYYY/jMM/jDD"
                custom-input=".start-date-picker"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                dense
                v-model="file.owner"
                hide-details
                outlined
                label="نام مالک"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                outlined
                @change="calcPriceMeter"
                label="نوع معامله را انتخاب کنید"
                :items="transactionTypes"
                item-value="id"
                item-text="name"
                v-model="file.transactionType"
                :messages="divar.adType"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                outlined
                label="نوع ملک را انتخاب کنید"
                :items="fileTypes"
                item-value="id"
                item-text="name"
                v-model="file.propertyType"
                :messages="divar.propertyType"
            />
          </v-col>

          <v-col :cols="3">
            <v-combobox
                dense
                :messages="divar.areaName"
                outlined
                label="انتخاب محله"
                :items="locations"
                item-value="Id"
                item-text="NeighborhoodName"
                v-model="file.location"
            />
          </v-col>

          <v-col :cols="3">
            <v-select
                dense
                multiple
                deletable-chips
                chips
                small-chips
                outlined
                label="جهت ملک"
                :items="fileDirections"
                item-value="id"
                item-text="name"
                v-model="file.direction"
                :messages="divar.buildingDirection"
            />
          </v-col>

        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <div class="map-container">
              <v-card
                  loader-height="2"
                  :loading="address.loading ? 'main' : false"
                  :disabled="address.loading"
                  elevation="5"
                  :class="['search-box',address.result.length ? 'fill' : '']">
                <v-card-text class="pa-3">

                  <v-text-field
                      dense
                      v-model="address.text"
                      @input="searchLocation"
                      prepend-icon="mdi-map-search-outline"
                      clearable
                      class="pa-0"
                      rounded
                      flat
                      placeholder="جستجو ادرس"
                  />

                  <v-list
                      class="search-location-result"
                      dense
                      two-line
                  >
                    <template v-for="(item,index) in address.result">
                      <v-list-item
                          link
                          @click="setMapCenter(item.geo_location.center)"
                          :key="index"
                          dense
                      >

                        <v-list-item-avatar
                            class="ma-0">
                          <v-icon>
                            mdi-map-marker
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-bold text-body-2 text-black">
                            {{ item.geo_location.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="font-weight-bold text-body-2 text-black">
                            {{ item.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                      </v-list-item>
                      <v-divider/>
                    </template>
                  </v-list>
                </v-card-text>
              </v-card>
              <div class="map" id='map'></div>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <div>

              <v-row class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.countfloors"
                      dense
                      outlined
                      type="number"
                      label="تعداد طبقه"
                      :messages="'تعداد طبقه : ' + divar.unitsPerFloor"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.numberofUnits"
                      @input="calcTotalNumberUnits"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="تعداد واحد"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      @focus="calcTotalNumberUnits"
                      v-model="file.totalNumberOfUnits"
                      dense
                      outlined
                      hide-details
                      type="number"
                      label="تعداد کل واحد"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.floorNumber"
                      dense
                      outlined
                      hide-details
                      label="طبقه"
                      item-text="name"
                      item-value="id"
                      :items="floorItems"

                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      @input="calcPriceMeter"
                      v-model="file.foundation"
                      dense
                      outlined
                      type="number"
                      label="زیر بنا"
                      :messages="'متراژ : ' + divar.squareMeters"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.numberOfSleeps"
                      :items="sleepRoomItems"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      label="تعداد خواب"
                      :messages=" 'تعداد خواب : ' +  divar.numberOfRooms"
                  />
                </v-col>
              </v-row>

              <v-row class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      @input="calcPriceMeter"
                      v-model="file.totalprice"
                      dense
                      outlined
                      label="قیمت کل / رهن"
                      :messages="divar.totalAmount"
                  />
                  {{ convertNumberToWords(file.totalprice) }}
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.pricePerMeter"
                      dense
                      outlined
                      hide-details
                      label="قیمت متری / اجاره"
                  />
                  {{ convertNumberToWords(file.pricePerMeter) }}
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.kitchenType"
                      :items="kitchenItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      label="اشپزخانه"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.phoneStatus"
                      :items="telephoneItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      label="تلفن"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.wctype"
                      :items="wcItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      label="بهداشتی"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.floorCoveringType"
                      :items="floorCoverItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      label="کفپوش"
                  />
                </v-col>
              </v-row>

              <v-row type="flex" align="center" justify="center" class="mb-4" dense>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.buildingage"
                      dense
                      outlined
                      :messages="`سال ساحت : ${divar.yearBuilt}`"
                      type="number"
                      label="سن بنا"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.viewtype"
                      :items="namaItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      label="نما"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-select
                      v-model="file.typeofDocument"
                      :items="documentItems"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      :messages="divar.documentType"
                      label="سند"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.landArea"
                      dense
                      outlined
                      type="number"
                      label="مساحت زمین"
                  />
                </v-col>
                <v-col :cols="2">
                  <v-text-field
                      v-model="file.lengthon"
                      dense
                      outlined
                      type="number"
                      label="طول بر"
                  />
                </v-col>
              </v-row>

            </div>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col cols="12" class="mb-4">
            {{ divar.features }}
          </v-col>


          <v-col
              v-for="(property ,index) in properties"
              :key="index"
              :cols="2">
            <v-checkbox
                v-model="file.properties"
                hide-details
                dense
                :value="property.id"
                :label="property.name"
            />
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col cols="3" v-if="file.image1">
            <Uploader
                v-model="file.image1"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image2">
            <Uploader
                v-model="file.image2"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image3">
            <Uploader
                v-model="file.image3"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image4">
            <Uploader
                v-model="file.image4"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image5">
            <Uploader
                v-model="file.image5"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image6">
            <Uploader
                v-model="file.image6"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image7">
            <Uploader
                v-model="file.image7"
                width="100%"/>
          </v-col>
          <v-col cols="3" v-if="file.image8">
            <Uploader
                v-model="file.image8"
                width="100%"/>
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <v-textarea
                dense
                v-model="file.descriptions"
                hide-details
                label="توضیحات"
                height="100"
                outlined
            />
          </v-col>
        </v-row>

        <v-row >

          <v-col :cols="3">
            <v-select
                clearable
                label="وضعیت فایل"
                outlined
                v-model="divar.status"
                hide-details
                item-value="id"
                item-text="name"
                :items="statusOptions"
            />
          </v-col>

          <v-col :cols="3">
            <v-switch
                flat
                hide-details
                label="فایل مشاور املاک"
                v-model="file.EstateFile"
            />
          </v-col>
          <v-col :cols="3">
            <v-switch
                flat
                hide-details
                label="اسال پیامک به مالک"
                v-model="file.sendSms"
            />
          </v-col>

          <v-col :cols="3">
            <v-switch
                flat
                hide-details
                label="فایل اختصاصی"
                v-model="file.special"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="3">
            <v-btn
                :disabled="!checkButton"
                :loading="file.loading"
                large color="success" @click="submitFile">
              ثبت فایل
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="relatedFiles.modal">
      <v-card>
        <v-card-title class="text-body-1 flex align-center justify-space-between">
          <h3>
            نمایش فایل های تکراری
          </h3>

          <v-btn color="danger" @click="relatedFiles.modal = false">
            بستن
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th v-for="(th,index) in relatedFiles.table.headers"
                    :key="index"
                    class="text-center table-header">
                  {{ th }}
                </th>
              </tr>
              </thead>
              <tbody class="table-body">
              <tr
                  v-for="(item,index) in relatedFiles.table.items"
                  :key="index"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">
                  <ImageViewer :src="item.image"/>
                </td>
                <td class="text-center">{{ item.user }}</td>
                <td class="text-center">{{ item.mobile }}</td>
                <td class="text-center">{{ item.ownerName }}</td>
                <td class="text-center">{{ item.floorNumber }}</td>
                <td class="text-center" style="width: 50%">{{ item.address }}</td>
                <td class="text-center" style="direction: ltr;">{{ item.created_at }}</td>
                <td class="text-center" style="width: 50%">{{ item.title }}</td>
                <td class="text-center flex align-center justify-center flex-column">
                  <v-chip
                      small
                      :color="item.status.color"
                      text-color="white">
                    {{ item.status.name }}
                  </v-chip>
                  <br>
                  <v-chip
                      class="mr-3"
                      v-if="item.fileTransaction_type.name"
                      small
                      :color="item.fileTransaction_type.color"
                      text-color="white">
                    {{ item.fileTransaction_type.name }}
                  </v-chip>
                </td>
                <td class="text-center">{{ item.totalprice }}</td>
                <td class="text-center">{{ item.pricePerMeter }}</td>

                <td class="text-center">

                  <v-btn
                      @click="item.actions.edit"
                      color="main"
                      small
                      icon
                      text
                      fab>
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>

                  <v-btn
                      @click="item.actions.delete"
                      color="error"
                      small
                      icon text fab>
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>


                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {convertNumberToWords} from "@Newfiling/Services"
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import Uploader from "@/components/Uploader"
import ImageViewer from "@/components/ImageViewer"
import PageTitle from "@/components/PageTitle"
import {
  deleteFile,
  fetchFileFromDivar, fetchFilesWithMobile,
  generateImageWithWatermark,
  searchLocations,
  submitFile, updateDivarFileStatus,
} from "@Newfiling/module-file/src/api";
import _ from 'lodash';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import {AppConfig} from "@Newfiling/Services";
import Vue from "vue";
import moment from "jalali-moment";
import {addCommas, removeCommas} from "@persian-tools/persian-tools";

export default {

  name: 'DesktopFileCreate',

  components: {
    PageTitle,
    Uploader,
    ImageViewer,
    datePicker: VuePersianDatetimePicker
  },

  data() {
    return {
      statusOptions: [],
      relatedFiles: {
        table: {
          headers: [
            '#',
            'عکس',
            'تنظیم کننده',
            'موبایل',
            'مالک',
            'طبقه',
            'ادرس',
            'تاریخ ثبت',
            'عنوان',
            'وضعیت',
            'قیمت کل / رهن',
            'قیمت متری / اجاره',
            'عملیات',
          ],
          items: [],
        },
        modal: false,
      },
      map: null,
      address: {
        lat: 35.70358951560828,
        lng: 51.3882064819336,
        loading: false,
        text: null,
        result: []
      },
      transactionTypes: [],
      fileTypes: [],
      fileDirections: [
        {
          id: 'N',
          name: 'شمالی'
        },
        {
          id: 'S',
          name: 'جنوبی'
        },
        {
          id: 'E',
          name: 'شرقی'
        },
        {
          id: 'W',
          name: 'غربی'
        },
      ],
      locations: [],
      floorItems: [],
      sleepRoomItems: [],
      kitchenItems: [],
      telephoneItems: [],
      wcItems: [],
      floorCoverItems: [],
      namaItems: [],
      documentItems: [],
      properties: [],
      status: [
        {
          id: 0,
          name: 'تایید شده',
          loading: false,
          color: "success",
        },
        {
          id: 1,
          name: 'اجاره رفته',
          loading: false,
          color: "warning",
        },
        {
          id: 2,
          name: 'به فروش رفته',
          loading: false,
          color: "warning",
        },
        {
          id: 3,
          name: 'قیمت تغییر کرده',
          loading: false,
          color: "primary",
        },
        {
          id: 4,
          name: 'رد فایل',
          loading: false,
          color: "error",
        },

      ],
      file: {
        dateSelection: moment().locale('fa').format('jYYYY/jMM/jDD'),
        loading: false,
        phone1: "",
        phone2: "",
        phone3: "",
        owner: "",
        location: "",
        transactionType: "",
        propertyType: "",
        direction: "",
        countfloors: null,
        numberofUnits: null,
        totalNumberOfUnits: null,
        floorNumber: null,
        foundation: null,
        numberOfSleeps: null,
        totalprice: "",
        pricePerMeter: "",
        kitchenType: null,
        phoneStatus: null,
        wctype: null,
        floorCoveringType: null,
        buildingage: null,
        viewtype: null,
        typeofDocument: null,
        landArea: null,
        lengthon: null,
        residenceoftheowner: false,
        hastenant: false,
        discharge: false,
        properties: [],
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        imageNewfiling1: null,
        imageNewfiling2: null,
        imageNewfiling3: null,
        imageNewfiling4: null,
        imageNewfiling5: null,
        imageNewfiling6: null,
        imageNewfiling7: null,
        imageNewfiling8: null,
        descriptions: null,
        zonkenPersonal: false,
        zonkenRealEstat: false,
        sendtonewfile: false,
        EstateFile: false,
        sendSms: true,
        special: false,
      },
      divar: {
        status: "",
        id: null,
        phoneNumber: null,
        adType: null,
        propertyType: null,
        areaName: null,
        squareMeters: null,
        yearBuilt: null,
        numberOfRooms: null,
        floornumber: null,
        unitsPerFloor: null,
        documentType: null,
        buildingDirection: null,
        depositAmount: null,
        totalAmount: null,
        rentAmount: null,
        pricePerSquareMeter: null,
        images1: null,
        images2: null,
        images3: null,
        images4: null,
        images5: null,
        images6: null,
        images7: null,
        images8: null,
        source: null,
        restroom: null,
        features: null,
        publicationStatus: null,
        description: null,
        registrationDate: null,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      uploaderKey: false,
    }
  },

  computed: {
    checkButton() {
      return Boolean(
          this.file.phone1 &&
          this.file.owner &&
          this.file.transactionType &&
          this.file.propertyType &&
          this.file.location &&
          this.address.lat &&
          this.address.lng &&
          this.address.text
      );
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست آگهی های دیوار',
        disabled: false,
        href: '/panel/files/newfiling-list',
      },
      {
        text: 'ثبت آگهی جدید از دیوار',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {

      this.init();
      const appConfigs = (await AppConfig) || {};
      this.statusOptions = appConfigs.callcenter.map(i => ({id: i.Id, name: i.Sqlqry1}));
      this.locations = appConfigs?.neighborhoods || [];
      this.transactionTypes = appConfigs?.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name: item.Typeoftransaction,
        }
      })
      this.fileTypes = appConfigs?.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.floorItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Floornumber.map(item => {
          return {
            id: item.Id,
            name: item.FloorName,
          }
        })
      ]
      this.sleepRoomItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Numberofsleeps.map(item => {
          return {
            id: item.Id,
            name: item.Numberofsleeps,
          }
        })
      ];
      this.kitchenItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.kitchen.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.telephoneItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Telephonelinenumber.map(item => {
          return {
            id: item.Id,
            name: item.Linestatus,
          }
        })
      ]
      this.wcItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.Wclist.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.floorCoverItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.FloorCovering.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.namaItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.ViewType.map(item => {
          return {
            id: item.Id,
            name: item.Servicename,
          }
        })
      ]
      this.documentItems = [
        {
          id: "",
          name: "",
        },
        ...appConfigs.typeofDocument.map(item => {
          return {
            id: item.Id,
            name: item.Documentname,
          }
        })
      ]
      this.properties = appConfigs.Features
          .map(item => {
            return {
              id: item.Id,
              name: item.Persianname,
            }
          })

      await this.fetchFileFromDivar();


      // Divar data convert:
      this.divarConvertDirection();


    })
  },

  methods: {
    convertNumberToWords,

    async fetchFilesWithMobile(mobile) {
      if (!mobile) return;
      this.file.loading = true;
      this.relatedFiles.table.items = [];
      const res = (await fetchFilesWithMobile(mobile))?.data || {
        message: [],
      };
      const getFileTitle = (file) => {
        let title = '';
        title = this.transactionTypes.find(i => i.id === file.typeOfTransaction)?.name + " ";
        title = title + this.fileTypes.find(i => i.id === file.propertyType)?.name + " در "
        title = title + this.locations.find(i => i.Id === file.locationsid)?.NeighborhoodName
        return title;
      }
      const getStatus = (file) => {
        switch (file.filestatus) {
          case 1: {
            return {
              value: file.filestatus,
              name: 'فعال',
              color: 'success'
            }
          }
          case 2: {
            return {
              value: file.filestatus,
              name: 'غیر فعال',
              color: 'error'
            }
          }
          default: {
            return {
              value: file.filestatus,
              name: 'نا معلوم',
              color: '#333'
            }
          }
        }
      }
      const getFileTransactionStatus = (file) => {
        switch (file.fileTransactiontype) {
          case 1: {
            return {
              name: 'اجاره رفته',
              color: 'warning'
            }
          }
          case 2: {
            return {
              name: 'فروش رفته',
              color: 'warning'
            }
          }
          case 3: {
            return {
              name: 'قیمت تغییر کرده',
              color: 'warning'
            }
          }
          case 4: {
            return {
              name: 'منصرف شده',
              color: 'warning'
            }
          }
          default: {
            return {
              name: null,
              color: null,
            }
          }
        }
      }
      const getMobiles = (item) => {
        let phones = '';
        if (item.phone1) {
          phones += item.phone1 + " - "
        }
        if (item.phone2) {
          phones += item.phone2 + " - "
        }
        if (item.phone3) {
          phones += item.phone3 + " - "
        }
        return phones;
      }
      const items = res?.message.map(item => {
        return {
          id: item.id,
          image: item.image1 || '/img/placeholder.png',
          mobile: getMobiles(item),
          user: item.regulatorname || 'نا معلوم',
          created_at: item.creationdate.split(' ')[0],
          title: getFileTitle(item),
          totalprice: addCommas(item.totalprice),
          pricePerMeter: addCommas(item.pricePerMeter),
          status: getStatus(item),
          filestatus: item.filestatus,
          fileTransaction_type: getFileTransactionStatus(item),
          ownerName: item.ownerName,
          address: item.propertyAddress,
          floorNumber: this.floorItems.find(i => i.id === item.floorNumber)?.name,
          actions: {
            edit: () => {
              this.$router.push('/panel/files/edit/' + item.id);
            },

            view: () => {
              this.file = {
                ...item,
                id: item.id,
                modal: true,
                title: getFileTitle(item),
                totalprice: addCommas(item.totalprice),
                pricePerMeter: addCommas(item.pricePerMeter),
                kitchenType: this.kitchenTypes.find(i => i.id === item.kitchenType)?.name,
                numberOfSleeps: this.sleepRoomItems.find(i => i.id === item.numberOfSleeps)?.name,
                floorNumber: this.floorItems.find(i => i.id === item.floorNumber)?.name,
                telephoneType: this.telephoneItems.find(i => i.id === item.phoneStatus)?.name,
                wctype: this.wcItems.find(i => i.id === item.wctype)?.name,
                floorCoveringType: this.floorCoverItems.find(i => i.id === item.floorCoveringType)?.name,
                viewType: this.viewItems.find(i => i.id === item.viewtype)?.name,
                typeofDocument: this.documentItems.find(i => i.id === item.typeofDocument)?.name,
              }
            },

            delete: () => {
              this.deleteItem = {
                ...this.deleteItem,
                id: item.id,
                modal: true,
                loading: false,
              }
            },

            replay: () => {
              this.newMessage = {
                modal: true,
                phoneNumber: item.phone1.substring(1),
              }
            },
          },
        }
      });
      this.relatedFiles = {
        ...this.relatedFiles,
        modal: res.message.length,
        table: {
          headers: [
            '#',
            'عکس',
            'تنظیم کننده',
            'موبایل',
            'مالک',
            'طبقه',
            'ادرس',
            'تاریخ ثبت',
            'عنوان',
            'وضعیت',
            'قیمت کل / رهن',
            'قیمت متری / اجاره',
            'عملیات',
          ],
          items,
        },
      }
      this.file.loading = false;
    },

    calcTotalNumberUnits() {
      if (this.file.countfloors && this.file.numberofUnits) {
        this.file.totalNumberOfUnits = parseInt(this.file.countfloors) * parseInt(this.file.numberofUnits)
      }
    },

    calcPriceMeter() {

      if (
          this.file.foundation &&
          this.file.totalprice &&
          (this.file.transactionType === 1 || this.file.transactionType === 5)) {
        const price = parseInt(removeCommas(this.file.totalprice)) / parseInt(this.file.foundation);
        this.file.pricePerMeter = this.roundedPrice(addCommas(price.toFixed(0)))
      }
    },

    async submitFile() {
      const normalize = (value) => {
        if (String(value).trim() === '') {
          return 0;
        }
        return value;
      }

      this.file.loading = true;
      try {
        await updateDivarFileStatus(this.divar?.id, this.divar?.status);
        const res = (await submitFile({
          ...this.file,
          phone1: String(this.file.phone1).trim(),
          phone2: String(this.file.phone2).trim(),
          phone3: String(this.file.phone3).trim(),
          ownerName: this.file.owner,
          locationsid: this.file.location?.Id,
          north: this.file.direction.includes('N'),
          south: this.file.direction.includes('S'),
          eastern: this.file.direction.includes('E'),
          western: this.file.direction.includes('W'),
          typeOfTransaction: this.file.transactionType,
          propertyType: this.file.propertyType,
          latitude: this.address.lat,
          longitude: this.address.lng,
          propertyAddress: this.address.text,
          totalprice: normalize(removeCommas(String(this.file.totalprice))),
          pricePerMeter: normalize(removeCommas(String(this.file.pricePerMeter))),
          attributes: this.file.properties.join('-'),
          descriptions: this.file.descriptions,
          landArea: this.file.landArea || 0,
        }))?.data;

        this.$toast.success(res.message);


        this.$router.go(-1);

      } catch (e) {
        console.log(e)
      }
      this.file.loading = false;
    },

    addImage(file, index) {
      this.file[`image${index}`] = file.url;
      this.file[`imageNewfiling${index}`] = file.url2;
    },

    init() {
      if (this.map) return;
      const center = [this.address.lat, this.address.lng];
      setTimeout(() => {
        this.map = L.map('map', {
          center,
          zoomControl: false,
          zoom: 15,
        });
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
            .addTo(this.map);

        this.map.attributionControl.setPrefix(false);
        let markerIcon = L.Icon.extend({
          options: {
            iconUrl: require('../../../assets/location.png'),
            iconSize: [50, 50],
            iconAnchor: [25, 48],
            shadowSize: [50, 50],
            shadowAnchor: [0, 48],
          }
        });
        let icon = new markerIcon();
        this.marker = new L.marker(center, {
          draggable: 'true',
          icon,
        });
        this.marker.on('dragend', () => {
          let position = this.marker.getLatLng();
          this.marker.setLatLng(position, {
            draggable: 'true'
          }).bindPopup(position).update();
          this.address.lat = position.lat;
          this.address.lng = position.lng;
          this.map.getCenter()
        });
        this.map.addLayer(this.marker);

        // this.map.on('moveend', (e) => {
        //   this.address = {
        //     ...this.address,
        //     ...this.map.getCenter()
        //   }
        // })

      }, 500);

    },

    searchLocation: _.debounce(async function () {
      if (!this.address.text) {
        this.address.result = [];
        return;
      }

      this.address.loading = true;
      try {
        const res = (await searchLocations(this.address.text))?.data?.results || [];

        this.address.result = res.map(item => {
          return {
            ...item,
          }
        });
      } catch (e) {
        console.log(e)
      }
      this.address.loading = false;
    }, 500),

    async setMapCenter(center) {
      this.map.flyTo(new L.LatLng(center.lat, center.lng));
      this.marker.setLatLng(new L.LatLng(center.lat, center.lng));
      this.address.result = [];
      this.address.lat = center.lat;
      this.address.lng = center.lng;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deleteFile(this.deleteItem.id);
        this.$toast.success('فایل مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

    roundedPrice(price) {
      let priceString = String(price); // 333,333,333

      if (priceString) {
        const priceAsArray = priceString.split(','); // [333,333,333]
        console.log({a: priceAsArray.length})
        console.log({priceAsArray})
        switch (priceAsArray.length) {
          case 4: {
            priceString = priceAsArray[0] + priceAsArray[1] + priceAsArray[2] + '000';
            break;
          }
          case 3: {
            priceString = priceAsArray[0] + priceAsArray[1] + '000';
            break;
          }

          case 2: {
            priceString = priceAsArray[0] + '000';
            break;
          }

        }
        return addCommas(priceString);

      }

      return priceString;
    },

    divarConvertDirection() {
      const TYPES = {
        'شمالی': 'N',
        'جنوبی': 'S',
        'شرقی': 'E',
        'غربی': 'W',
      };
      if (this.divar.buildingDirection) {
        this.file.direction = TYPES[this.divar.buildingDirection];
      }

    },

    async fetchFileFromDivar() {
      this.file.loading = true;
      const {data} = await fetchFileFromDivar(this.$route.params?.id);

      this.divar = {
        ...this.divar,
        id: data?.id,
        status: data?.publicationStatus,
        phoneNumber: data?.phoneNumber,
        adType: data?.adType,
        propertyType: data?.propertyType,
        areaName: data?.areaName,
        squareMeters: data?.squareMeters,
        yearBuilt: data?.yearBuilt,
        numberOfRooms: data?.numberOfRooms,
        floornumber: data?.floornumber,
        unitsPerFloor: data?.unitsPerFloor,
        documentType: data?.documentType,
        buildingDirection: data?.buildingDirection,
        depositAmount: data?.depositAmount,
        totalAmount: data?.totalAmount,
        rentAmount: data?.rentAmount,
        pricePerSquareMeter: data?.pricePerSquareMeter,
        images1: data?.images1,
        images2: data?.images2,
        images3: data?.images3,
        images4: data?.images4,
        images5: data?.images5,
        images6: data?.images6,
        images7: data?.images7,
        images8: data?.images8,
        source: data?.source,
        restroom: data?.restroom,
        features: data?.features,
        publicationStatus: data?.publicationStatus,
        description: data?.description,
        registrationDate: data?.registrationDate,
      }
      this.file = {
        ...this.file,
        phone1: data?.convertPhoneNumber,
        phone2: "",
        phone3: "",
        owner: "",
        location: this.locations?.find(i => i.Id === data?.convertAreaName),
        transactionType: Number(data?.convertAdType),
        propertyType: Number(data?.convertPropertyType),
        direction: data?.convertBuildingDirection,
        countfloors: data?.convertTotalFloors,
        numberofUnits: data?.convertUnitsPerFloor,
        totalNumberOfUnits: data?.convertFloornumber * data?.convertUnitsPerFloor,
        floorNumber: data?.convertFloornumber,
        foundation: data?.convertSquareMeters,
        numberOfSleeps: Number(data?.convertNumberOfRooms),
        totalprice: data?.convertTotalAmount,
        pricePerMeter: data?.convertPricePerSquareMeter,
        kitchenType: null,
        phoneStatus: null,
        wctype: null,
        floorCoveringType: null,
        buildingage: data?.convertYearBuilt,
        viewtype: null,
        typeofDocument: data?.convertDocumentType,
        landArea: null,
        lengthon: null,
        residenceoftheowner: false,
        hastenant: false,
        discharge: false,
        properties: String(data?.convertFeatures).split('-')?.map(i => Number(i)),
        image1: data?.convertImages1,
        image2: data?.convertImages2,
        image3: data?.convertImages3,
        image4: data?.convertImages4,
        image5: data?.convertImages5,
        image6: data?.convertImages6,
        image7: data?.convertImages7,
        image8: data?.convertImages8,
        imageNewfiling1: data?.convertImages1,
        imageNewfiling2: data?.convertImages2,
        imageNewfiling3: data?.convertImages3,
        imageNewfiling4: data?.convertImages4,
        imageNewfiling5: data?.convertImages5,
        imageNewfiling6: data?.convertImages6,
        imageNewfiling7: data?.convertImages7,
        imageNewfiling8: data?.convertImages8,
        descriptions: data?.convertDescription,
        zonkenPersonal: false,
        zonkenRealEstat: false,
        sendtonewfile: false,
        EstateFile: false,
        sendSms: true,
        special: false,
      }
      this.file.loading = false
      this.generateImageWithWatermark();
    },

    generateImageWithWatermark() {
      if (this.divar?.images1) {
        generateImageWithWatermark(this.divar?.images1).then(({data}) => {
          this.file.image1 = data?.link1;
        })
      }

      if (this.divar?.images2) {
        generateImageWithWatermark(this.divar?.images2).then(({data}) => {
          this.file.image2 = data?.link1;
        })
      }

      if (this.divar?.images3) {
        generateImageWithWatermark(this.divar?.images3).then(({data}) => {
          this.file.image3 = data?.link1;
        })
      }

      if (this.divar?.images4) {
        generateImageWithWatermark(this.divar?.images4).then(({data}) => {
          this.file.image4 = data?.link1;
        })
      }
      if (this.divar?.images5) {
        generateImageWithWatermark(this.divar?.images5).then(({data}) => {
          this.file.image5 = data?.link1;
        })
      }
      if (this.divar?.images6) {
        generateImageWithWatermark(this.divar?.images6).then(({data}) => {
          this.file.image6 = data?.link1;
        })
      }
      if (this.divar?.images7) {
        generateImageWithWatermark(this.divar?.images7).then(({data}) => {
          this.file.image7 = data?.link1;
        })
      }
      if (this.divar?.images8) {
        generateImageWithWatermark(this.divar?.images8).then(({data}) => {
          this.file.image8 = data?.link1;
        })
      }


    }

  },

  watch: {
    'file.totalprice'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.file.totalprice = result);
    },
    'file.pricePerMeter'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.file.pricePerMeter = result);
    },
  }





  /*


  {
  divar:
      {
       "id": 115,
        "phoneNumber": "09126512547",
        "adType": "اجاره مسکونی",
        "propertyType": "آپارتمان",
        "areaName": "آذربایجان",
        "squareMeters": "70",
        "yearBuilt": "1399",
        "numberOfRooms": "1",
        "floornumber": "2 از 4",
        "unitsPerFloor": "2",
        "documentType": null,
        "buildingDirection": "شمالی",
        "depositAmount": "0",
        "totalAmount": "0",
        "rentAmount": "0",
        "pricePerSquareMeter": "0",
        "images1": "https://s100.divarcdn.com/static/photo/post/JMUxR4xOLsFoVP2Grc1psg/e153b167-ba0b-4b17-80e8-1fbe2f13043c.jpg",
        "images2": "https://s100.divarcdn.com/static/photo/post/rutaPv-tZ2pg3eVjDhuWkA/f718a0b7-bb83-454f-9c3c-a805d88f69fd.jpg",
        "images3": "https://s100.divarcdn.com/static/photo/post/QHlJX0UgejbWD3WLDMoUNw/91efa3de-fb07-419b-8b2b-d39174cfd1a6.jpg",
        "images4": "https://s100.divarcdn.com/static/photo/post/tBb9EcYK8fx-Ea-BDmqdmw/dd83f89a-2a70-41a4-8d37-86b349e05326.jpg",
        "images5": "https://s100.divarcdn.com/static/photo/post/xsCHBDt0aWUPtFlI7jVN2g/68acdde6-4fd7-40d4-bc22-79fc0ab41744.jpg",
        "source": "https://divar.ir/v/----/QZGUydw4",
        "restroom": null,
        "features": null
      },
      file:
      {
         "phoneNumber": "09126512547",
      }
  }


   */
}
</script>

<style scoped>

.map-container {
  position: relative;
  z-index: 1;
}

.map-container .marker-icon-custom {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  height: 60px;
  margin: auto;
}

#map {
  width: 100%;
  height: 350px;
  direction: ltr !important;
  border-radius: 10px;
}

#map * {
  direction: ltr !important;
}

a[href="https://leafletjs.com"] {
  display: none !important;
}

span[aria-hidden] {
  display: none !important;
}

.map-container .search-box {
  /*position: absolute;*/
  top: 10px;
  z-index: 9999;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 10px;
  height: 60px;
  overflow: hidden;
}

.map-container .search-box.fill {
  height: auto;
}

.search-location-result {
  overflow-y: auto;
  max-height: 250px;
}
</style>
